// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-inwestorow-index-js": () => import("./../../../src/pages/dla-inwestorow/index.js" /* webpackChunkName: "component---src-pages-dla-inwestorow-index-js" */),
  "component---src-pages-dokumenty-prawne-index-js": () => import("./../../../src/pages/dokumenty-prawne/index.js" /* webpackChunkName: "component---src-pages-dokumenty-prawne-index-js" */),
  "component---src-pages-dokumenty-prawne-regulamin-3000-js": () => import("./../../../src/pages/dokumenty-prawne/regulamin-3000.js" /* webpackChunkName: "component---src-pages-dokumenty-prawne-regulamin-3000-js" */),
  "component---src-pages-firma-index-js": () => import("./../../../src/pages/firma/index.js" /* webpackChunkName: "component---src-pages-firma-index-js" */),
  "component---src-pages-generalne-wykonawstwo-index-js": () => import("./../../../src/pages/generalne-wykonawstwo/index.js" /* webpackChunkName: "component---src-pages-generalne-wykonawstwo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-index-js": () => import("./../../../src/pages/kariera/index.js" /* webpackChunkName: "component---src-pages-kariera-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-oferta-index-js": () => import("./../../../src/pages/oferta/index.js" /* webpackChunkName: "component---src-pages-oferta-index-js" */),
  "component---src-pages-oferta-osiedle-gdynia-wiczlino-2-etap-1-2-js": () => import("./../../../src/pages/oferta/osiedle-gdynia-wiczlino-2/etap-1-2.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-gdynia-wiczlino-2-etap-1-2-js" */),
  "component---src-pages-oferta-osiedle-gdynia-wiczlino-2-index-js": () => import("./../../../src/pages/oferta/osiedle-gdynia-wiczlino-2/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-gdynia-wiczlino-2-index-js" */),
  "component---src-pages-oferta-osiedle-gdynia-wiczlino-index-js": () => import("./../../../src/pages/oferta/osiedle-gdynia-wiczlino/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-gdynia-wiczlino-index-js" */),
  "component---src-pages-oferta-osiedle-janowo-park-2-index-js": () => import("./../../../src/pages/oferta/osiedle-janowo-park-2/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-janowo-park-2-index-js" */),
  "component---src-pages-oferta-osiedle-janowo-park-3-index-js": () => import("./../../../src/pages/oferta/osiedle-janowo-park-3/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-janowo-park-3-index-js" */),
  "component---src-pages-oferta-osiedle-janowo-park-index-js": () => import("./../../../src/pages/oferta/osiedle-janowo-park/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-janowo-park-index-js" */),
  "component---src-pages-oferta-osiedle-pogodne-w-pucku-index-js": () => import("./../../../src/pages/oferta/osiedle-pogodne-w-pucku/index.js" /* webpackChunkName: "component---src-pages-oferta-osiedle-pogodne-w-pucku-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-program-3000-index-js": () => import("./../../../src/pages/program-3000/index.js" /* webpackChunkName: "component---src-pages-program-3000-index-js" */),
  "component---src-pages-zakup-gruntow-index-js": () => import("./../../../src/pages/zakup-gruntow/index.js" /* webpackChunkName: "component---src-pages-zakup-gruntow-index-js" */)
}

